/* eslint-disable */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Build from "@material-ui/icons/Build";
import Subject from "@material-ui/icons/Subject";
import FormatPaint from "@material-ui/icons/FormatPaint";
import Code from "@material-ui/icons/Code";
import Dashboard from "@material-ui/icons/Dashboard";
import Timeline from "@material-ui/icons/Timeline";
import Group from "@material-ui/icons/Group";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Badge from "components/Badge/Badge.js";

import projectsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/projectsStyle.js";

import office2 from "assets/img/examples/office2.jpg";
import cardBlog3 from "assets/img/examples/card-blog3.jpg";
import cardProject1 from "assets/img/examples/card-project1.jpg";
import cardProject2 from "assets/img/examples/card-project2.jpg";
import cardProject3 from "assets/img/examples/card-project3.jpg";
import cardProject4 from "assets/img/examples/card-project4.jpg";
import cardProject5 from "assets/img/examples/card-project5.jpg";
import cardProject6 from "assets/img/examples/card-project6.jpg";
import computerAi from "assets/img/computer-ai.jpg";
import computerAnalytics from "assets/img/computer-analytics.jpg";
import computerPhysician from "assets/img/computer-physician.jpg";


const useStyles = makeStyles(projectsStyle);

export default function SectionProjects({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Project 1 START */}
      
      {/* Project 1 END */}
      {/* Project 2 START */}
      <div className={classes.projects}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <Muted>
                <h6>OUR WORK</h6>
              </Muted>
              <h2 className={classes.title}>
                Operationalize Insights
              </h2>
              <h5 className={classes.description}>
                Leverage cutting-edge machine learning and artificial intelligence solutions for high quality healthcare.
                As the healthcare ecosystem changes and evolves with new technology, it is important for healthcare organizations to generate more insight from their data and make smarter decisions about patient care.
                Our solutions can help you achieve those critical insights.
              </h5>
              <div className={classes.sectionSpace} />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <Card plain className={classes.card2}>
                  <CardHeader image plain>
                    <img
                      src={
                        computerAnalytics
                      }
                      alt="..."
                    />
                  </CardHeader>
                <CardBody plain>
                  <h4 className={classes.cardTitle}>
                    Localization
                  </h4>
                  <h6 className={classes.description}>Risk-Adjusted Algorithms</h6>
                  <p
                    className={classes.description + " " + classes.marginTop20}
                  >
                    Uses hospital and provider specific data to adjust for local variations.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card plain className={classes.card2}>
                  <CardHeader image plain>
                    <img
                      src={computerPhysician}
                      alt="..."
                    />
                  </CardHeader>
                <CardBody plain>
                  <h4 className={classes.cardTitle}>
                    Real-Time Monitoring
                  </h4>
                  <h6 className={classes.description}>Actionable Insights</h6>
                  <p
                    className={classes.description + " " + classes.marginTop20}
                  >
                    Real time analysis pushes insights to providers at the point of care.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card plain className={classes.card2}>
                  <CardHeader image plain>
                    <img
                      src={computerAi}
                      alt="..."
                    />
                  </CardHeader>
                <CardBody plain>
                  <h4 className={classes.cardTitle}>
                    Cutting-Edge Tools
                  </h4>
                  <h6 className={classes.description}>Retrospective - Real-Time - Predictive</h6>
                  <p
                    className={classes.description + " " + classes.marginTop20}
                  >
                    Add the power of Machine Learning and Artificial Intelligence to your clinical decision making.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Project 2 END */}
      {/* Project 3 START */}
    </div>
  );
}
