import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Share from "@material-ui/icons/Share";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
// core components
import Header from "components/Header/Header.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { UserContext } from "utils/UserProvider.jsx";
import Info from "components/Typography/Info.js";
import { socialLinks } from "../../../utils/socialLinks";

import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";

import bg12 from "assets/img/bg12.jpg";
import office2 from "assets/img/examples/office2.jpg";
import dg1 from "assets/img/dg1.jpg";
import dg2 from "assets/img/dg2.jpg";
import dg3 from "assets/img/dg3.jpg";
import bg7 from "assets/img/bg7.jpg";
import forestbg1 from "assets/img/forest-bg1.jpg";
import forestbg2 from "assets/img/forest-bg2.jpg";

const useStyles = makeStyles(headersStyle);

export default function SectionHeaders({ ...rest }) {
  const user = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");

  const classes = useStyles();
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplaySpeed: 7000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  const renderHeader = () => {
    if(user){
      return (      
        <div>
        <Carousel {...settings}>
          {/* Carousel 1 START */}
          <div>
            <div
              className={classes.pageHeader}
              style={{ backgroundImage: `url("${dg1}")` }}
            >
              <div className={classes.container}>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <h1 className={classes.title}>Rainier Data Labs</h1>
                    <h4>
                    We provide data analytics, science, and engineering applications and consulting services
                    to help you get the most out of your data.
                    </h4>
                    <br />
                    <h6>Connect with us on:</h6>
                    <div>
                      <Button color="white" simple size="lg" justIcon>
                        <i className="fab fa-twitter" />
                      </Button>
                      <Button color="white" simple size="lg" justIcon>
                        <i className="fab fa-facebook-square" />
                      </Button>
                      <Button color="white" simple size="lg" justIcon>
                        <i className="fab fa-instagram" />
                      </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
          {/* Carousel 1 END */}
          {/* Carousel 2 START */}
          <div>
            <div
              className={classes.pageHeader}
              style={{ backgroundImage: `url("${forestbg1}")` }}
            >
              <div className={classes.container}>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={8}
                    md={8}
                    className={classNames(
                      classes.mlAuto,
                      classes.mrAuto,
                      classes.textCenter
                    )}
                  >
                    <h1 className={classes.title}>CARE Score</h1>
                    <h4>
                    Our AI driven CARE Score algorithm leverages registry data to
                    identify provider strengths and weaknesses, providing a roadmap
                    for better clinical outcomes and registry performance.
                    </h4>
                    <br />
                     <Button color="danger" size="lg">
                      Get started
                    </Button>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
          {/* Carousel 2 END */}
          {/* Carousel 3 START */}
          <div>
            <div
              className={classes.pageHeader}
              style={{ backgroundImage: `url("${dg3}")` }}
            >
              <div className={classes.container}>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={7}
                    md={7}
                    className={classNames(classes.mlAuto, classes.textRight)}
                  >
                    <h1 className={classes.title}>CARE Score</h1>
                    <h4>
                    Benchmark quality metrics using our CARE Score application.
                    Trend performance over time and track improvement.
                    </h4>
                    <br />
                    <div>
                    <Button color="white" simple size="lg">
                      <Share /> Get Started!
                    </Button>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
          </div>
          {/* Carousel 3 END */}
        </Carousel>
      </div>)
    } else {
      return (
        <div>
        <div
          className={classes.pageHeader}
          style={{ backgroundImage: `url("${bg7}")` }}
        >
          <div className={classes.containerHeader2}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <h1 className={classes.title}>You should work with us!</h1>
                <h4>
                    We provide data analytics, science, and engineering applications and consulting services
                    to help you get the most out of your data.
                </h4>
              </GridItem>
              <GridItem
                xs={12}
                sm={10}
                md={10}
                className={classNames(classes.mlAuto, classes.mrAuto)}
              >
                <Card raised className={classes.card}>
                  <CardBody formHorizontal>
                    <form>
                      <GridContainer>
                        <GridItem xs={12} sm={3} md={3}>
                          <CustomInput
                            id="name"
                            inputProps={{
                              placeholder: "display name",
                              value: displayName,
                              onChange: event => setDisplayName(event.currentTarget.value)
                            }}
                            formControlProps={{
                              fullWidth: true,
                              className: classes.formControl
                            }}
                            value={displayName}
                            onChange={(event) => setDisplayName(event.currentTarget.value)}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3}>
                          <CustomInput
                            id="email"
                            inputProps={{
                              placeholder: "email",
                              value: email,
                              onChange: event => setEmail(event.currentTarget.value)
                            }}
                            formControlProps={{
                              fullWidth: true,
                              className: classes.formControl
                            }}

                          />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3}>
                          <CustomInput
                            id="password"
                            inputProps={{
                              placeholder: "password",
                              type: "password",
                              autoComplete: "off",
                              value: password,
                              onChange: event => setPassword(event.currentTarget.value)
                            }}
                            formControlProps={{
                              fullWidth: true,
                              className: classes.formControl
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={3} md={3}>
                          <Link   to={{
                            pathname: "/signup-page",
                            state: { displayName: displayName, email: email, password: password }
                          }} >
                            <Button
                              block
                              color="primary"
                              className={classes.button}
                            >
                              Sign up
                            </Button>
                          </Link>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
        )
    }
  }

  const renderHeader1 = () => {
      return (
          <div>
            <Carousel {...settings}>
              {/* Carousel 1 START */}
              <div>
                <div
                    className={classes.pageHeader}
                    style={{ backgroundImage: `url("${bg7}")` }}
                >
                  <div className={classes.container}>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <h1 className={classes.title}>Rainier Data Labs</h1>
                        <h4>
                          We provide data analytics, science, and engineering applications
                          to help hospital based healthcare providers leverage their data.
                        </h4>
                        <br />
                        <h6>Connect with us on:</h6>
                        <div>
                          <Button color="white" simple size="lg" justIcon onClick={()=> window.open(socialLinks.companyTwitter, "_blank")}>
                            <i className="fab fa-twitter" />
                          </Button>
                          <Button color="white" simple size="lg" justIcon onClick={()=> window.open(socialLinks.companyLinkedIn, "_blank")}>
                            <i className="fab fa-linkedin" />
                          </Button>
                          <Button color="white" simple size="lg" justIcon onClick={()=> window.open(socialLinks.companyInstagram, "_blank")}>
                            <i className="fab fa-instagram" />
                          </Button>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              </div>
              {/* Carousel 1 END */}
              {/* Carousel 2 START */}
              <div>
                <div
                    className={classes.pageHeader}
                    style={{ backgroundImage: `url("${forestbg2}")` }}
                >
                  <div className={classes.container}>
                    <GridContainer>
                      <GridItem
                          xs={12}
                          sm={8}
                          md={8}
                          className={classNames(
                              classes.mlAuto,
                              classes.mrAuto,
                              classes.textCenter
                          )}
                      >
                        <h1 className={classes.title}>Our Promise</h1>
                        <h4>
                          We serve hospitals by providing innovative data-driven insights at affordable prices to drive high quality patient care.
                        </h4>
                        <br />
                        <Button color="danger" size="lg">
                          Get started
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              </div>
              {/* Carousel 2 END */}
              {/* Carousel 3 START */}
              <div>
                <div
                    className={classes.pageHeader}
                    style={{ backgroundImage: `url("${forestbg1}")` }}
                >
                  <div className={classes.container}>
                    <GridContainer>
                      <GridItem
                          xs={12}
                          sm={7}
                          md={7}
                          className={classNames(classes.mlAuto, classes.textRight)}
                      >
                        <h1 className={classes.title}>Customer Experience</h1>
                        <h4>
                          We aim to be the most innovative provider of healthcare analytics in the world.
                          We provide fast and reliable cutting-edge tools that customers count on.
                        </h4>
                        <br />
                        <div>
                          <Button color="white" simple size="lg">
                            <Share /> Get Started!
                          </Button>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              </div>
              {/* Carousel 3 END */}
            </Carousel>
          </div>)
  }
  return (
    // we've set the className to cd-section so we can make smooth scroll to it
    <div className="cd-section" {...rest}>
      {renderHeader1()}
    </div>
  );
}
