import React, { useContext } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import AboutUsPage from "views/AboutUsPage/AboutUsPage.js";
import BlogPostPage from "views/BlogPostPage/BlogPostPage.js";
import BlogPostsPage from "views/BlogPostsPage/BlogPostsPage.js";
import ComponentsPage from "views/ComponentsPage/ComponentsPage.js";
import ContactUsPage from "views/ContactUsPage/ContactUsPage.js";
import SectionContactUsPage from "app/MainPage/Sections/SectionContacts.js";
import EcommercePage from "views/EcommercePage/EcommercePage.js";
import LandingPage from "views/LandingPage/LandingPage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import PresentationPage from "views/PresentationPage/PresentationPage.js";
import PricingPage from "views/PricingPage/PricingPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import ProductPage from "views/ProductPage/ProductPage.js";
import SectionsPage from "views/SectionsPage/SectionsPage.js";
import ShoppingCartPage from "views/ShoppingCartPage/ShoppingCartPage.js";
import SignupPage from "views/SignupPage/SignupPage.js";
import ErrorPage from "app/ErrorPage/ErrorPage.js";
import PasswordResetPage from "views/PasswordResetPage/PasswordResetPage.js";
import UpdatePasswordPage from "views/UpdatePasswordPage/UpdatePasswordPage.jsx";
import MainPage from "app/MainPage/MainPage.jsx";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinksNew.jsx";
import FooterLinks from "./components/Footer/FooterLinks";
import { UserContext } from "utils/UserProvider.jsx";

import logo from "assets/img/logos/rdl_logo_inverse_text.svg";
import {useHistory, useLocation} from "react-router-dom";
import PrivacyPolicy from "app/Legal/PrivacyPolicy";
import TermsAndConditions from "app/Legal/TermsAndConditions";

var hist = createBrowserHistory();

export default function App() {
  const user = useContext(UserContext);
  return(
    <Router history={hist}>
        <Header
            color="transparent"
            changeColorOnScroll={{
                height: 100,
                color: "primary"
            }}
            brand={logo}

            links={<HeaderLinks dropdownHoverColor="rose" />}
            fixed
        />
      <Switch>
          <Route path="/about-us" component={AboutUsPage} />
          <Route path="/contact-us" component={SectionContactUsPage} />
          <Route path="/login-page" component={LoginPage} />
          <Route path="/signup-page" component={SignupPage} />
          <Route path="/password-reset-page" component={PasswordResetPage} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-and-conditions" component={TermsAndConditions} />
          {user ? <Route path="/profile-page" component={ProfilePage} /> : null}
          {user ? <Route path="/update-password-page" component={UpdatePasswordPage} /> : null}
          <Route exact path="/" component={MainPage} />
          <Route path="*" component={ErrorPage} />
          {
              /*
              <Route path="/blog-post" component={BlogPostPage} />
              <Route path="/blog-posts" component={BlogPostsPage} />
              <Route path="/components" component={ComponentsPage} />

              <Route path="/ecommerce-page" component={EcommercePage} />
              <Route path="/landing-page" component={LandingPage} />
              <Route path="/pricing" component={PricingPage} />
              <Route path="/product-page" component={ProductPage} />
              <Route path="/sections" component={SectionsPage} />
              <Route path="/shopping-cart-page" component={ShoppingCartPage} />
              <Route path="/error-page" component={ErrorPage} />
              <Route path="/presentation-page" component={PresentationPage} />

               */
          }
      </Switch>
      <FooterLinks />
    </Router>
    )
}
