import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// import  from "@material-ui/icons/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";
import { socialLinks } from "../../../utils/socialLinks";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";

import forestbg2 from "assets/img/forest-bg2.jpg";
import dave from "assets/img/dave.jpg";
import brian from "assets/img/brian.jpg";
import city from "assets/img/examples/city.jpg";
import marc from "assets/img/faces/marc.jpg";
import christian from "assets/img/faces/christian.jpg";
import kendall from "assets/img/faces/kendall.jpg";
import avatar from "assets/img/faces/avatar.jpg";
import cardProfile1 from "assets/img/examples/card-profile1.jpg";
import cardProfile2 from "assets/img/examples/card-profile2.jpg";
import cardProfile4 from "assets/img/examples/card-profile4.jpg";
import cardProfile1Square from "assets/img/faces/card-profile1-square.jpg";
import cardProfile2Square from "assets/img/faces/card-profile2-square.jpg";
import cardProfile4Square from "assets/img/faces/card-profile4-square.jpg";
import cardProfile6Square from "assets/img/faces/card-profile6-square.jpg";

const useStyles = makeStyles(teamsStyle);

export default function SectionTeams({ ...rest }) {
  const classes = useStyles();
  return (
      <div
        className={classes.team + " " + classes.section}
        style={{ backgroundImage: `url(${forestbg2})` }}
        {...rest}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Our Team</h2>
              <h5 className={classes.descriptionWhite}>
                Our founding team created Rainier Data Labs with the belief that modern tools are required to solve modern problems.
                With a combined 50 years of experience in the healthcare technology industry, Brian and David bring a combination of technical and clinical expertise
                to build quality products centered around providers needs first.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <Card profile>
                <CardAvatar profile>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img src={brian} alt="..." />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>Brian McAuley</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>CEO / CO-FOUNDER</h6>
                  </Muted>
                  <p className={classes.description}>
                    Brian brings over 10 years of experience in healthcare technology leadership to his position as CEO. Prior to founding Rainier Data Labs, Brian worked as the Director of Engineering with Lumedx and Intelerad,
                    successfully building and scaling multiple product lines across analytics, imaging, and data collection/aggregation. Brian is focused on helping healthcare organizations leverage their data through innovative software solutions.

                    Brian received a B.A. from the University of Washington.
                  </p>
                </CardBody>
                <CardFooter profile className={classes.justifyContent}>
                  <Button justIcon round color="linkedin" onClick={()=> window.open(socialLinks.brianLinkedIn, "_blank")}>
                    <i className="fab fa-linkedin-in" />
                  </Button>
                  <Button justIcon round color="twitter" onClick={()=> window.open(socialLinks.companyTwitter, "_blank")}>
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button justIcon round color="instagram" onClick={()=> window.open(socialLinks.companyInstagram, "_blank")}>
                    <i className="fab fa-instagram" />
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card profile>
                <CardAvatar profile>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img src={dave} alt="..." />
                  </a>
                </CardAvatar>
                <CardBody>
                  <h4 className={classes.cardTitle}>David McAuley</h4>
                  <Muted>
                    <h6 className={classes.cardCategory}>CSO / CO-FOUNDER</h6>
                  </Muted>
                  <p className={classes.description}>
                    Dr. McAuley founded Lumedx Corporation over 30 years ago. He used his training from Temple University Medical School, the University of Washington School of Public Health, and the Centers for Disease Control, combined with his passion for technology,
                    to guide the company through decades of success and a subsequent acquisition. He brings that passion to Rainier Data Labs with a vision to become the most innovative provider of clinical analytics in the world.
                  </p>
                </CardBody>
                <CardFooter profile className={classes.justifyContent}>
                  <Button justIcon round color="linkedin" onClick={()=> window.open(socialLinks.daveLinkedIn, "_blank")}>
                    <i className="fab fa-linkedin-in" />
                  </Button>
                  <Button justIcon round color="twitter" onClick={()=> window.open(socialLinks.companyTwitter, "_blank")}>
                    <i className="fab fa-twitter" />
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
  );
}
