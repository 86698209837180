import React from "react";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import contactsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js";

import city from "assets/img/examples/city.jpg";
import classNames from "classnames";
import {generateContactMail, logAnalyticsEvent} from "utils/firebase";
import Info from "../../../components/Typography/Info";

const useStyles = makeStyles(contactsStyle);

const RegularMap = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: 44.43353, lng: 26.093928 - 0.025 }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
      <Marker position={{ lat: 44.43353, lng: 26.093928 }} />
    </GoogleMap>
  ))
);

export default function SectionContacts({ ...rest }) {
  const [checked, setChecked] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [validation, setValidation] = React.useState({firstName: false, lastName: false, email: false, message: false, checked: false, anyError: false})
  const [confirmation, setConfirmation] = React.useState("");

  const handleToggle = value => {
    setChecked(!checked);
  };
  const handleFirstName = value => {
    setFirstName(value);
  }
  const handleLastName = value => {
    setLastName(value);
  }
  const handleEmail = value => {
    setEmail(value);
  }
  const handleMessage = value => {
    setMessage(value);
  }
  const handleSubmit = () => {
    if(validate()){
      generateContactMail({firstName, lastName, email, message}).then(r => {
        setConfirmation("Your message was sent!");
        setTimeout(() => {
          setConfirmation("")
          setFirstName('');
          setLastName('');
          setEmail('');
          setMessage('');
          setChecked(false);
        }, 3000);
        logAnalyticsEvent("contact_us_message_sent")
      })
    }
  }
  const validate = () => {
    const val = {...validation};
    if(!firstName){
      val.firstName = true
    }else{
      val.firstName = false
    }
    if(!lastName){
      val.lastName = true
    }else{
      val.lastName = false
    }
    if(!email){
      val.email = true
    }else{
      val.email = false
    }
    if(!message){
      val.message = true
    }else{
      val.message = false
    }
    if(!checked){
      val.checked = true
    }else{
      val.checked = false
    }

    val.anyError = val.firstName || val.lastName || val.email || val.message || val.checked

    setValidation(val)
    setTimeout(() => {setValidation({firstName: false, lastName: false, email: false, message: false, checked: false, anyError: false})}, 5000);

    return !val.anyError;
  }
  const classes = useStyles();
  const cardHeaderClasses = classNames({
    [classes.textCenter]: true,
    [classes.cardHeader]: true
  });
  const checkboxLabelClasses = classNames({
    [classes.label]: true,
    [classes.errorLabel]: validation.checked
  })
  return (
    <div className="cd-section" {...rest}>
      {/* Contact us 1 START */}
      <div
        className={classes.contacts + " " + classes.section}
        style={{ backgroundImage: `url(${city})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={5} md={5}>
              <h2 className={classes.title}>Get in Touch</h2>
              <h5 className={classes.description}>
                We'd love to hear from you! Reach out with any comments, questions, or requests for more information.
              </h5>
              <InfoArea
                className={classes.infoArea}
                title="Office"
                description={
                  <span>
                    1031 108th Ave SE,
                    <br /> Bellevue, WA, 90884
                    <br /> USA
                  </span>
                }
                icon={PinDrop}
                light
              />
              <InfoArea
                className={classes.infoArea}
                title="Phone"
                description={
                  <span>
                    Rainier Data Labs
                    <br /> 425.477.9094
                    <br /> Mon - Fri, 9:00-17:00 PST
                  </span>
                }
                icon={Phone}
                light
              />
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <Card className={classes.card1}>
                <form>
                  <CardHeader
                    contact
                    className={cardHeaderClasses}
                  >
                    <h4 className={classes.cardTitle}>Contact Us</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      {confirmation ? <GridItem xs={12}><Info>{confirmation}</Info></GridItem> : null}
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="First Name"
                          id="first"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: firstName,
                            onChange: (e) => handleFirstName(e.target.value),
                            error: validation.firstName
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="Last Name"
                          id="last"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            value: lastName,
                            onChange: (e) => handleLastName(e.target.value),
                            error: validation.lastName
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <CustomInput
                      labelText="Email Address"
                      id="email-address"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: email,
                        onChange: (e) => handleEmail(e.target.value),
                        error: validation.email
                      }}
                    />
                    <CustomInput
                      labelText="Your Message"
                      id="message"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                        value: message,
                        onChange: (e) => handleMessage(e.target.value),
                        error: validation.message
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentBetween}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          checked={checked}
                          onClick={() => handleToggle(1)}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      classes={{ label: checkboxLabelClasses }}
                      label="I'm not a robot"
                    />
                    <Button color="primary" className={classes.pullRight} onClick={() => handleSubmit()}>
                      Send Message
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Contact us 1 END */}
      {/* Contact us 2 START */}
      
      {/* Contact us 2 END */}
    </div>
  );
}
