import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.aboutDescription, classes.textCenter)}>
      <GridContainer>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h5 className={classes.description}>
            We create applications that allow hospital based healthcare providers to monitor the patient care they provide in real-time.
            We aim to eliminate the need for dashboards or data analysts by leveraging predictive analytics algorithms and artificial intelligence techniques.
            Process improvements are suggested based on proven practices implemented at similar hospitals.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
