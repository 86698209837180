import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
import Business from "@material-ui/icons/Business";
import AccountBalance from "@material-ui/icons/AccountBalance";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";
import Success from "components/Typography/Success.js";
import Danger from "components/Typography/Danger.js";

import pricingStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/pricingStyle.js";

import bg11 from "assets/img/bg11.jpg";
import city from "assets/img/examples/city.jpg";
import cardBlog3 from "assets/img/examples/card-blog3.jpg";

const useStyles = makeStyles(pricingStyle);

export default function SectionPricing({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Pricing 1 START */}
      <div
        className={
          classes.pricing + " " + classes.pricing1 + " " + classes.section
        }
        style={{ backgroundImage: `url(${bg11})` }}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Pick the best plan for you</h2>
              <h5 className={classes.description}>
                Always a free tier available for single users! Scale up as your team grows.
              </h5>
              <div className={classes.sectionSpace} />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card pricing plain>
                <CardBody pricing plain>
                  <h6
                    className={
                      classes.cardCategory + " " + classes.marginBottom20
                    }
                  >
                    JUST ME!
                  </h6>
                  <div className={classes.icon}>
                    <Weekend className={classes.iconWhite} />
                  </div>
                  <h3
                    className={
                      classes.cardTitleWhite + " " + classes.marginTop30
                    }
                  >
                    FREE
                  </h3>
                  <p className={classes.cardCategory}>
                    For a team of 1.
                  </p>
                  <Button round color="white">
                    Choose plan
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card pricing raised>
                <CardBody pricing>
                  <h6
                    className={
                      classes.cardDescription + " " + classes.marginBottom20
                    }
                  >
                    SMALL TEAM
                  </h6>
                  <div className={classes.icon}>
                    <Home className={classes.iconRose} />
                  </div>
                  <h3 className={classes.cardTitle + " " + classes.marginTop30}>
                    $150
                  </h3>
                  <p className={classes.cardDescription}>
                    Our most popular plan.
                    For teams between 2 and 5 people.
                  </p>
                  <Button round color="rose">
                    Choose plan
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card pricing plain>
                <CardBody pricing plain>
                  <h6
                    className={
                      classes.cardCategory + " " + classes.marginBottom20
                    }
                  >
                    MEDIUM TEAM
                  </h6>
                  <div className={classes.icon}>
                    <Business className={classes.iconWhite} />
                  </div>
                  <h3
                    className={
                      classes.cardTitleWhite + " " + classes.marginTop30
                    }
                  >
                    $500
                  </h3>
                  <p className={classes.cardCategory}>
                    For teams between 6 and 20 people.
                  </p>
                  <Button round color="white">
                    Choose plan
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={3}>
              <Card pricing plain>
                <CardBody pricing plain>
                  <h6
                    className={
                      classes.cardCategory + " " + classes.marginBottom20
                    }
                  >
                    ENTERPRISE
                  </h6>
                  <div className={classes.icon}>
                    <AccountBalance className={classes.iconWhite} />
                  </div>
                  <h3
                    className={
                      classes.cardTitleWhite + " " + classes.marginTop30
                    }
                  >
                    $1000
                  </h3>
                  <p className={classes.cardCategory}>
                    For teams over 20 people.
                  </p>
                  <Button round color="white">
                    Choose plan
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Pricing 1 END */}
      {/* Pricing 2 START */}

      {/* Pricing 5 END */}
    </div>
  );
}
