import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDPeTozGlT_53Pjis3zWL70_4eEs790YA8",
  authDomain: "rainier-data-labs.firebaseapp.com",
  projectId: "rainier-data-labs",
  storageBucket: "rainier-data-labs.appspot.com",
  messagingSenderId: "793799971627",
  appId: "1:793799971627:web:18e9e7bbf7790887cf2b6e",
  measurementId: "G-PSSYQ25K74"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
  auth.signInWithPopup(provider);
};

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return;
  const userRef = firestore.doc(`users/${user.uid}`);
  const snapshot = await userRef.get();
  if (!snapshot.exists) {
    const { email, displayName, photoURL, emailVerified } = user;
    try {
      await userRef.set({
        displayName,
        email,
        photoURL,
        emailVerified,
        ...additionalData
      });
    } catch (error) {
      console.error("Error creating user document", error);
    }
  }
  return getUserDocument(user.uid);
};

const getUserDocument = async uid => {
  if (!uid) return null;
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();
    return {
      uid,
      ...userDocument.data()
    };
  } catch (error) {
    console.error("Error fetching user", error);
  }
};

export const generateContactMail = async (mail) => {
  const uid = uuidv4();
  const mailRef = firestore.doc(`mail/${uid}`);
  const { firstName, lastName, email, message } = mail;
    try {
      await mailRef.set({
        to: ['info@rainierdatalabs.com'],
        message: {
          subject: `Contact Us - ${firstName} ${lastName}, ${email}`,
          text: message,
          html: message,
        }
      });
    } catch (error) {
      console.error("Error creating mail document", error);
    }
  return getContactMailDocument(uid);
};

const getContactMailDocument = async uid => {
  if (!uid) return null;
  try {
    const mailDocument = await firestore.doc(`mail/${uid}`).get();
    return {
      uid,
      ...mailDocument.data()
    };
  } catch (error) {
    console.error("Error fetching mail", error);
  }
};

export const logAnalyticsEvent = (eventName) => {
  firebase.analytics().logEvent(eventName);
}

