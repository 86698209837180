/* eslint-disable */
import React, { useContext } from "react";
import { auth } from 'utils/firebase.js';
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
import Avatar from "@material-ui/core/Avatar";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import ViewDay from "@material-ui/icons/ViewDay";
import Dns from "@material-ui/icons/Dns";
import Build from "@material-ui/icons/Build";
import ListIcon from "@material-ui/icons/List";
import People from "@material-ui/icons/People";
import Assignment from "@material-ui/icons/Assignment";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import Chat from "@material-ui/icons/Chat";
import Call from "@material-ui/icons/Call";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import AccountBalance from "@material-ui/icons/AccountBalance";
import ArtTrack from "@material-ui/icons/ArtTrack";
import ViewQuilt from "@material-ui/icons/ViewQuilt";
import LocationOn from "@material-ui/icons/LocationOn";
import Fingerprint from "@material-ui/icons/Fingerprint";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Store from "@material-ui/icons/Store";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Layers from "@material-ui/icons/Layers";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import LineStyle from "@material-ui/icons/LineStyle";
import Error from "@material-ui/icons/Error";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import { UserContext } from "utils/UserProvider.jsx";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import classNames from "classnames";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const user = useContext(UserContext);

  const classes = useStyles();
  const signInButtonClasses = classNames({
    [classes.navButton]: true,
    [classes.signInButton]: true
  });

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  const renderAuthLinks = () => {
    if(user){
      return(
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Avatar}
          caret={false}
          buttonIconProps={{
            src: user.photoURL,
            alt: user.displayName,
            style: {
              height: "30px",
              width: "30px"
            }
          }}
          dropdownList={[
          <Link
            to="/profile-page"
            className={classes.dropdownLink}
          >
            Profile
          </Link>,
          <Link
            to="/"
            className={classes.dropdownLink}
            onClick={() => auth.signOut()}
          >
            Sign out
          </Link>
          ]}
        />
        )
    } else {
      return(
        <Link to="/login-page" className={classes.dropdownLink}>
          <Button
            target="_blank"
            color="warning"
          >
            Sign in
          </Button>
        </Link>)
    }
  }

  var onClickSections = {};

  const { dropdownHoverColor } = props;
  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <Link to="/about-us" className={classes.navLink}>
          About
        </Link>
      </ListItem>
        {
            /*
            <ListItem className={classes.listItem}>
                <Link to="/pricing" className={classes.navLink}>
                    Pricing
                </Link>
            </ListItem>
             */
        }
        {
            /*
            <ListItem className={classes.listItem}>
                <CustomDropdown
                    noLiPadding
                    navDropdown
                    hoverColor={dropdownHoverColor}
                    buttonText="Products"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    dropdownList={[
                        <Link to="/product-page" className={classes.dropdownLink}>
                            <Assignment className={classes.dropdownIcons} /> CareScore
                        </Link>
                    ]}
                />
            </ListItem>
             */
        }

      <ListItem className={classes.listItem}>
        <Link to="/contact-us" className={classes.navLink}>
          Contact
        </Link>
      </ListItem>
      {

          renderAuthLinks()

      }
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};
