/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// core components
import Footer from "components/Footer/Footer.js";

import errorPageStyle from "assets/jss/material-kit-pro-react/views/errorPageStyles.js";
import {Link} from "react-router-dom";

const useStyles = makeStyles(errorPageStyle);

export default function FooterLinks({ ...rest }) {
    const classes = useStyles();
    return (
        <div>
            <Footer
                content={
                    <div>
                        <div className={classes.left}>
                            <List className={classes.list}>
                                <ListItem className={classes.inlineBlock}>
                                    <Link to="/about-us" className={classes.block}>
                                        About
                                    </Link>
                                </ListItem>
                            </List>
                        </div>
                        <div className={classes.rightFloat}>
                            <List className={classes.list}>
                                <ListItem className={classes.inlineBlock}>
                                    <Link to="/privacy-policy" className={classes.block}>
                                        Privacy Policy
                                    </Link>
                                </ListItem>
                                <ListItem className={classes.inlineBlock}>
                                    <Link to="/terms-and-conditions" className={classes.block}>
                                        Terms and Conditions
                                    </Link>
                                </ListItem>
                                <ListItem className={classes.inlineBlock}>
                                    <div className={classes.block}>&copy; {1900 + new Date().getYear()} Rainier Data Labs LLC</div>
                                </ListItem>
                            </List>
                        </div>
                    </div>
                }
            />
        </div>
    );
}